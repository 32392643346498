<template>
  <div class="order-detail-wrap">
    <b-container fluid>
      <h2 class="page-title">订单详情</h2>
      <div class="orderDetail-name">
        <span>订单信息</span>
      </div>
      <b-container fluid>
        <b-row class="orderDetail-info">
          <b-col class="orderDetail-info-left">
            <div class="info-item">
              <span class="info-title">订单编号：</span>
              <span class="info-txt">{{ orderDetails.order_id }}</span>
            </div>
            <div class="info-item">
              <span class="info-title">收货地址：</span>
              <span class="info-txt">
                {{ orderDetails.address }} {{ orderDetails.name }} {{ orderDetails.phone }}
              </span>
            </div>
            <div class="info-item">
              <span class="info-title">发票抬头：</span>
              <span class="info-txt">{{ orderDetails.invoices_rise }}</span>
            </div>
            <div class="info-item">
              <span class="info-title">买家留言：</span>
              <span class="info-txt">{{ orderDetails.leaving_message }}</span>
            </div>
            <div class="info-item">
              <span class="info-title">配送方式：</span>
              <span class="info-txt" v-if="orderDetails.distribution_mode == 1">平台配送</span>
              <span class="info-txt" v-else>商家配送</span>
            </div>
          </b-col>
          <b-col class="orderDetail-info-right">
            <div class="order-status">
              <img src="@/../public/img/atention.jpg" alt="" />
              <span class="order-status-title">订单状态：</span>
              <span v-show="orderDetails.order_status == 0" class="order-status-close">待付款</span>
              <span v-show="orderDetails.order_status == 1" class="order-status-close">已付款</span>
              <span v-show="orderDetails.order_status == 2" class="order-status-close">待发货</span>
              <span v-show="orderDetails.order_status == 3" class="order-status-close">待收货</span>
              <span v-show="orderDetails.order_status == 4" class="order-status-close">已完成，等待买家评价</span>
              <span v-show="orderDetails.order_status == 5" class="order-status-close">已关闭</span>
              <span v-show="orderDetails.order_status == 6" class="order-status-close">退款中</span>
              <span v-show="orderDetails.order_status == 7" class="order-status-close">退款成功</span>
              <span v-show="orderDetails.order_status == 8" class="order-status-close">
                退款驳回<br /><br /><br />
                {{ orderDetails.operation_description }}
              </span>
            </div>
            <div class="delete-order" v-if="orderDetails.order_status == 0">
              <span class="delete-order-txt">请尽快完成付款，超时订单将自动关闭<br />您可以</span>
              <span class="immediately-order-btn" @click="immediatePayment">立即付款</span>&nbsp;&nbsp;
              <span class="delete-order-btn">取消订单</span>
            </div>
            <div class="delete-order" v-if="orderDetails.order_status == 1">
              <span class="delete-order-txt">订单状态：买家已付款，等待审核<br />您可以</span>
              <span class="delete-order-btn">申请退款</span>&nbsp;&nbsp;
            </div>
            <div class="delete-order" v-if="orderDetails.order_status == 2">
              <span class="delete-order-txt">订单状态：审核通过，待发货<br />您可以</span>
              <span class="delete-order-btn">申请退款</span>&nbsp;&nbsp;
            </div>
            <div class="delete-order" v-if="orderDetails.order_status == 3">
              <span class="delete-order-txt">订单状态：已发货，等待买家确认<br />您可以</span>
              <span class="delete-order-btn">确认收货</span>&nbsp;&nbsp;
            </div>
            <div class="delete-order" v-if="orderDetails.order_status == 4"></div>
            <div class="delete-order" v-if="orderDetails.order_status == 5">
              <span class="delete-order-txt">请尽快完成付款，超时订单将自动关闭<br />您可以</span>
              <span class="delete-order-btn" @click="deleteOrder">删除订单</span>&nbsp;&nbsp;
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-if="!isMobile">
        <b-row class="order-title">
          <b-col class="goods-title" sm="6" md="4" lg="3" xl="7">
            商品
          </b-col>
          <b-col class="unit-price" sm="6" md="4" lg="3" xl="2">
            单价(元)
          </b-col>
          <b-col class="number-of" sm="6" md="4" lg="3" xl="1"> 数量 </b-col>
          <b-col class="subtotal" sm="6" md="4" lg="3" xl="2"> 小计 </b-col>
        </b-row>
      </b-container>
      <b-container fluid>
        <b-row class="order-cont" v-for="(item,index) in orderGoodDetails" :key="index">
          <b-col class="goods" sm="7" md="7" lg="7" xl="7">
            <div class="goods-in">
              <img :src="item.img_path" alt="" />
              <div class="goods-txt">
                <span class="goods-name">{{item.goods_name}}</span>
                <span class="goods-sku">{{item.goods_parameter}}</span>
              </div>
            </div>
          </b-col>
          <b-col class="items-name" cols="12" v-if="isMobile">
            <span>单价(元)</span>
            <span>数量</span>
            <span>小计</span>
          </b-col>
          <b-col class="price" sm="2" md="2" lg="2" xl="2">
            <span>{{$t("vipPaymentMethodCurrency")}}{{item.unit_price | formatPrice}}</span>
          </b-col>
          <b-col class="number" sm="2" md="2" lg="2" xl="1">
            <span>{{item.goods_num}}</span>
          </b-col>
          <b-col class="subtotal-price" sm="2" md="2" lg="2" xl="2">
            <span>{{$t("vipPaymentMethodCurrency")}}{{item.total_price | formatPrice}}</span>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid>
        <b-row class="order-bot">
          <b-col class="integral" m="6" md="6" lg="6" xl="6">
            <span>返积分{{orderDetails.integral}}点</span>
          </b-col>
          <b-col class="total-amount" sm="6" md="6" lg="6" xl="6">
            <div class="total">
              <span class="total-txt">应付总额：</span>
              <span
                class="total-num">{{$t("vipPaymentMethodCurrency")}}{{orderDetails.total_price | formatPrice}}</span>
            </div>
            <span class="export-orders" @click="exportOrders">导出订单</span>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
  import dump from "@/util/dump";
  import Url from "@/api/config";
  export default {
    data() {
      return {
        orderDetails: "",
        order_id: "",
        orderGoodDetails: [],
        isMobile: false
      };
    },
    filters: {
      formatPrice(val) {
        let tmp = (new Number(val)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').split('.0')[0]
        return tmp;
      }
    },
    mounted() {
      if (this.$route.query.order_id) {
        this.order_id = this.$route.query.order_id;
      }
      this.getOrderDetail();
      this.windowResize();
      addEventListener("resize", this.windowResize, false);
    },
    methods: {
      // 取消订单
      cancelOrder() {
        this.$api.cancelOrder({
          order_id: this.order_id,
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            this.$message({
              message: '取消成功',
              type: 'success'
            });
            this.$router.back()
          }
        }).catch(err => console.error(err))
      },
      // 立即支付
      immediatePayment() {
        const params = {
          type: 1,
          link: "/orderSettlement",
          params: {
            orderid: this.order_id
          }
        }
        dump.link(params);
      },
      exportOrders() {
        window.open(Url + "/appexpertorder/excelDetails.do?order_id=" + this.order_id)
      },
      deleteOrder() {
        let that = this
        this.$api.deleteOrder({
          order_id: that.order_id
        }).then((res) => {
          if (res.status == 100) {
            that.$message({
              message: "删除成功！",
              type: "success",
            });
            that.$router.back()
          }
        })
      },
      getOrderDetail() {
        let that = this;
        this.$api.orderDetails({
          order_id: that.order_id
        }).then((res) => {
          if (res.status == 100) {
            that.orderDetails = res.data;
            if (res.data.childs) {
              that.orderGoodDetails = res.data.childs;
            } else {
              that.orderGoodDetails.push(res.data);
            }
          }
        });
      },
      windowResize() {
        let pageW = document.documentElement.clientWidth || document.body.clientWidth;
        this.isMobile = pageW < 768 ? true : false;
      }
    },
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/orderDetail/orderDetail";
</style>
